import { Grid } from "@mui/material";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import propTypes from "prop-types";

import { useTranslation } from "react-i18next";

// Images
import profilePicture from "assets/images/profiles/other.png";
import { author } from "App";

const ArticleProfile = ({profile}) => {
  const { t } = useTranslation();
  let bgImage = profilePicture;
  let name = "unkown";

  switch (true) {
    case profile === "Bruno":
      name = author;
      bgImage = profilePicture;
  }
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item>
        <MKAvatar src={profilePicture} alt={name} />
      </Grid>
      <Grid item>
        <MKTypography component="span" variant="body2">
        {t("by")} {name}
        </MKTypography>
      </Grid>
      <Grid item>
        <MKTypography component="span" variant="body2">
          {t("updatedAt")} 11.09.2024
        </MKTypography>
      </Grid>
    </Grid>
  );
}

ArticleProfile.propTypes = {
  profile: propTypes.string
};


export default ArticleProfile;
