/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";

// Images
import post1 from "assets/images/examples/testimonial-6-2.jpg";
import post2 from "assets/images/examples/testimonial-6-3.jpg";
import post3 from "assets/images/examples/blog-9-4.jpg";
import post4 from "assets/images/examples/blog2.jpg";
import MKBadge from "components/MKBadge";

import { useTranslation } from "react-i18next";
import { articles, getArticleImage, getArticleSquareImage, getArticleTitle } from "../articles/Article";

function Posts() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="gain money"
            container
            sx={{ mb: 2 }}
          /> */}
          <MKTypography variant="h2" fontWeight="bold">
            {t("posts.title")}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            {t("posts.subtitle")}
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container spacing={3}>
            {Object.keys(articles).map((key) => (
              <Grid item xs={12} sm={6} lg={3} key={key}>
                <TransparentBlogCard
                  image={getArticleSquareImage(key)}
                  title={getArticleTitle(t, key)}
                  description={t(`articles.${key}.summary`)}
                  banner={articles[key].banner}
                  action={{
                    type: "internal",
                    route: `/articles/${key}`,
                    color: "info",
                    label: t("articles.readMore"),
                  }}
                />
              </Grid>
            ))}
          {/* <Grid item xs={12} sm={6} lg={3}>
            <BackgroundBlogCard
              image={post4}
              title="Flexible work hours"
              description="Rather than worrying about switching offices every couple years, you stay in the same place."
              action={{
                type: "internal",
                route: "/pages/blogs/author",
                label: "read more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Posts;
