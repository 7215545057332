/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";

import './i18n'; // Importer le fichier de configuration i18next
import Article from "pages/Presentation/articles/Article";
import ContactUs from "pages/ContactUs";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'G-VKFGE5FM3Q'
}

TagManager.initialize(tagManagerArgs)

// i18n.changeLanguage("en");

export const emailAdress = "lameilleurecrypto@gmail.com";

export const author = "Julien Delaunay"; 

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/cgu" element={<Article name="cgu" legal/>} />
        {/* <Route path="/legal" element={<Article name="legal" legal/>} /> */}
        <Route path="/affiliation" element={<Article name="affiliation" legal/>} />
        <Route path="/confident" element={<Article name="confident" legal/>} />
        <Route path="/cookie" element={<Article name="cookie" legal/>} />
        <Route path="/mention" element={<Article name="mention" legal/>} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
