import { Grid } from "@mui/material";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import propTypes from "prop-types";

import { useTranslation } from "react-i18next";

import MKBox from "components/MKBox";

const ArticleTitle = ({children}) => (
  <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
    <MKTypography variant="h2">{children}</MKTypography>
  </MKBox>
);

ArticleTitle.propTypes = {
  children: propTypes.node,
};

export default ArticleTitle;
