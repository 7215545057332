/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import react from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";


// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Box, Grid } from "@mui/material";
import ArticleProfile from "./ArticleInfo";
import ArticleTitle from "./ArticleTitle";

import PropTypes from "prop-types";
import Markdown from "react-markdown";


// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Author page sections
// import Posts from "pages/LandingPages/Author/sections/Posts";

// Routes
import routes from "routes";

import MainFooter from "examples/Footers/MainFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Images
import img_best_cryp from "assets/images/articles/best-cryp.webp";
import img_best_plat from "assets/images/articles/best-plat.webp";
import img_best_wall from "assets/images/articles/best-wall.webp";
import img_comp_wall from "assets/images/articles/comp-wall.webp";
import img_earn_mone from "assets/images/articles/earn-mone.webp";
import img_pres_cryp from "assets/images/articles/pres-cryp.webp";
import img_pres_wall from "assets/images/articles/pres-wall.webp";
import img_best_cryp_c from "assets/images/articles/best-cryp-c.webp";
import img_best_plat_c from "assets/images/articles/best-plat-c.webp";
import img_best_wall_c from "assets/images/articles/best-wall-c.webp";
import img_comp_wall_c from "assets/images/articles/comp-wall-c.webp";
import img_earn_mone_c from "assets/images/articles/earn-mone-c.webp";
import img_pres_cryp_c from "assets/images/articles/pres-cryp-c.webp";
import img_pres_wall_c from "assets/images/articles/pres-wall-c.webp";

import img_legal from "assets/images/legal.webp";

import { article as best_cryp } from "./content/best-cryp.js";
import { article as best_plat } from "./content/best-plat.js";
import { article as best_wall } from "./content/best-wall.js";
import { article as comp_wall } from "./content/comp-wall.js";
import { article as earn_mone } from "./content/earn-mone.js";
import { article as pres_cryp } from "./content/pres-cryp.js";
import { article as pres_wall } from "./content/pres-wall.js";
import { content as cgu } from "./legal/cgu.js";
// import { content as legal } from "./legal/legal.js";
import { content as affiliation } from "./legal/affiliation.js";
import { content as confident } from "./legal/confident.js";
import { content as cookie } from "./legal/cookie.js";
import { content as mention } from "./legal/mention.js";
import Affiliate from "../Affiliate";
import { Stack } from "@mui/material";
import MKButton from "components/MKButton";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { emailAdress } from "App";
import { author } from "App";

export const articles = {
  'pres-cryp': {
    "image": img_pres_cryp,
    "image_c": img_pres_cryp_c,
    "content": pres_cryp,
  },
  'best-cryp': {
    "image": img_best_cryp,
    "image_c": img_best_cryp_c,
    "content": best_cryp,
  },
  'pres-wall': {
    "image": img_pres_wall,
    "image_c": img_pres_wall_c,
    "content": pres_wall,
  },
  'best-wall': {
    "image": img_best_wall,
    "image_c": img_best_wall_c,
    "content": best_wall,
  },
  'comp-wall': {
    "image": img_comp_wall,
    "image_c": img_comp_wall_c,
    "content": comp_wall,
    "banner": true,
  },
  'best-plat': {
    "image": img_best_plat,
    "image_c": img_best_plat_c,
    "content": best_plat,
    "banner": true,
  },
  'earn-mone': {
    "image": img_earn_mone,
    "image_c": img_earn_mone_c,
    "content": earn_mone,
  },
};

const legaux = {  
  'cgu': {
    "image": img_legal,
    "content": cgu,
  },
  // 'legal': {
  //   "image": img_legal,
  //   "content": legal,
  // },
  'affiliation': {
    "image": img_legal,
    "content": affiliation,
  },
  'confident': {
    "image": img_legal,
    "content": confident,
  },
  'cookie': {
    "image": img_legal,
    "content": cookie,
  },
  'mention': {
    "image": img_legal,
    "content": mention,
  },
};

// Fonction pour obtenir l'article en fonction du type
const getArticleContent = (name) => {
  return {...articles, ...legaux}[name].content || '...';
};

export const getArticleImage = (name) => {
  return {...articles, ...legaux}[name].image || '...';
};

export const getArticleSquareImage = (name) => {
  return {...articles, ...legaux}[name].image_c || '...';
};

export const getArticleTitle = (t, name) => {
  return t(`articles.${name}.title`) || '...';
};

function Article({ name, legal }) {
  const { t } = useTranslation();
  let bgImage = getArticleImage(name);
  let content = getArticleContent(name);
  return <>
    <DefaultNavbar
      routes={routes}
      transparent
      light
    />
    <MKBox bgColor="white">
      <MKBox
        minHeight={legal ? "10rem" : "30rem"}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
            <MKBox component="section" py={{ xs: 6, sm: 12 }}>
              <Container>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
                      {!legal && (
                        <>
                          <ArticleTitle>
                            {getArticleTitle(t, name)}
                          </ArticleTitle>
                          <ArticleProfile profile="Bruno"/>
                        </>
                      )}
                      <Markdown
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm ]}
                        // remarkPlugins={[remarkGfm]}
                        components={{
                          // Map `h1` (`# heading`) to use `h2`s.
                          pre: 'div',
                          // Rewrite tags
                          h1(props) {
                            // eslint-disable-next-line react/prop-types
                              const { node, ...rest } = props;
                              return <MKBox mt={4} mb={3}>
                                  <MKTypography variant={`h2`} {...rest} />
                              </MKBox>
                          },
                          h2(props) {
                            // eslint-disable-next-line react/prop-types
                              const { node, ...rest } = props;
                              return <MKBox mt={3} mb={2}>
                                  <MKTypography variant={`h3`} {...rest} />
                              </MKBox>
                          },
                          h3(props) {
                            // eslint-disable-next-line react/prop-types
                              const { node, ...rest } = props;
                              return <MKBox mt={2} mb={1}>
                                  <MKTypography variant={`h4`} {...rest} />
                              </MKBox>
                          },
                          h4(props) {
                            // eslint-disable-next-line react/prop-types
                              const { node, ...rest } = props;
                              return <MKBox mt={2} mb={1}>
                                  <MKTypography variant={`h5`} {...rest} />
                              </MKBox>
                          },
                          h6(props) {
                            // eslint-disable-next-line react/prop-types
                              const { node, ...rest } = props;
                              return <MKTypography variant="body1" fontWeight="light" color="text"  {...rest}>
                                <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                              </MKTypography>
                          },
                          span: ({ node, ...props }) => {
                            if (props.className === 'email') {
                              return emailAdress;
                            }
                            if (props.className === 'pseudo') {
                              return author;
                            }
                            return <span {...props} />;
                          },
                          code(props) {
                            const {children, className, node, ...rest} = props
                            const matchList = /language-list/.exec(className || '')
                            const matchPros = /language-pros/.exec(className || '')
                            const matchCons = /language-cons/.exec(className || '')
                            if (/language-binance/.exec(className || '')) {
                              return <Affiliate simple name="binance"/>;
                              // return <Grid container justifyContent="left" sx={{ m: 4}}>
                              // <Stack direction="row" alignItems="center" spacing={1}>
                              //   <MKButton color="affiliationButton" size="large">
                              //     Ouvrir un compte chez Binance
                              //     <Icon sx={{ ml: 1 }}>favorite</Icon>
                              //   </MKButton>
                              // </Stack>
                              // </Grid>;
                            }
                            // if (/language-email/.exec(className || '')) {
                            //   return <MKTypography variant="body1" fontWeight="light" color="text" {...rest} >
                            //     emaaaaa
                            // </MKTypography>
                            // }
                            // console.log("code>>>", className)                            
                            const IconComponent = matchPros ? "check" : (matchCons ? "close" : ArrowForwardIcon);
                            const color = matchPros ? "success" : (matchCons ? "error" : "text")
                            // eslint-disable-next-line react/prop-types
                            const list = children.split(/[£]/).map((element, i) => (
                              <MKTypography variant="body1" fontWeight="light" color="text" key={`code-list-${i}`} {...rest} className={className} >
                                <Icon color={color} sx={{ fontWeight: "bold" }}>
                                {typeof IconComponent === 'string' ? IconComponent : <IconComponent />}
                                </Icon>
                                &nbsp;&nbsp;<Markdown
                                  components={{
                                    p: react.Fragment, // Supprime les balises <p> générées
                                  }}
                                >
                                  {element.trim()}
                                </Markdown>
                              </MKTypography>
                            ));
                            return matchList || matchPros || matchCons ? list : (
                              <code {...rest} className={className}>
                                {children}
                              </code>
                            )
                          },
                          p(props) {
                            // eslint-disable-next-line react/prop-types
                            const { node, ...rest } = props;
                            return <MKTypography variant="body1" fontWeight="light" color="text"   {...rest} />;
                          },
                          ul(props) {
                            // eslint-disable-next-line react/prop-types
                            const { node, ...rest } = props;
                            return <ul className="MuiList-root MuiList-padding css-1ye9zee" {...rest} />;
                          },
                          li(props) {
                            // eslint-disable-next-line react/prop-types
                            const { node, ...rest } = props;
                            return <li className="MuiListItem-root MuiListItem-gutters css-ykjcgo">
                              <MKTypography variant="body1" fontWeight="light" color="text"   {...rest} />
                              </li>;
                          }
                        }}
                      >
                        {content}
                      </Markdown>
                      <Box sx={{ m: 6 }} />
                      {legal ? (
                        <>
                      <Grid container spacing={3} mb={3}>
                          <Grid item>
                            <MKTypography component="span" variant="body2">
                              {t("updatedAt")} 11.09.2024
                            </MKTypography>
                          </Grid>
                        </Grid>
                        </>
                      ) : (
                        <Affiliate/>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </MKBox>
      </Card>
      <MainFooter/>
    </MKBox>
  </>;
}

Article.propTypes = {
  children: PropTypes.node,
  legal: PropTypes.bool,
  name: PropTypes.string
};

Article.defaultProps = {
  legal: false,
};

export default Article;
