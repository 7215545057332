import { Box } from "@mui/material";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function Affiliate({ name, simple }) {
    const { t } = useTranslation();
    return <>
    {simple && <Box sx={{ m: 2 }} />}
    <FilledInfoCard
    variant={simple ? "contained" : "gradient"}
    color="info"
    icon={simple ? null : "flag"}
    title={simple ? null : t("affil.title")} 
    description={t("affil.description")}
    action={{
      type: "external",
      route: "https://accounts.binance.com/register?ref=F13PXRPZ",
      label: t("affil.action"),
      button: true,
    }}
  />
  </>;
}

Affiliate.propTypes = {
    simple: PropTypes.bool,
    name: PropTypes.string.isRequired
};

Affiliate.defaultProps = {
    simple: false,
};

export default Affiliate;
