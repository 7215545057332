/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import emailjs from '@emailjs/browser'; // Import from @emailjs/browser

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/digital.webp";
import MainFooter from "examples/Footers/MainFooter";

import { useTranslation } from "react-i18next";

function ContactUs() {
  const { t } = useTranslation();

    // State for form inputs and errors
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState("");

    // Validation function
  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = t("contact.msg_err_name_required");
    if (!email) {
      newErrors.email = t("contact.msg_err_email_requis");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = t("contact.msg_err_email");
    }
    if (!message || message.length < 20) {
      newErrors.message = t("contact.msg_err_min_car");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
        // Use EmailJS to send the email
        const serviceID = 'service_t4ver9j';
        const templateID = 'template_db43nii';
        const userID = 'nGYIY_K_V3D24KbKK';
  
        const templateParams = {
          name,
          email,
          message,
        };
  
        emailjs.send(serviceID, templateID, templateParams, userID)
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setSuccessMessage(t("contact.msg_sent"));
            setName("");
            setEmail("");
            setMessage("");
            setErrors({});
          })
          .catch((error) => {
            console.log('FAILED...', error);
            setErrors({ global: t("contact.msg_err") });
          });
    }
  };
// console.log("err:", errors  )
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          // action={{
          //   type: "external",
          //   route: "https://www.creative-tim.com/product/material-kit-react",
          //   label: "free download",
          //   color: "info",
          // }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                {t("contact.title")}
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                {t("contact.message")}
              </MKTypography>
              <MKBox width="100%" component="form" onSubmit={handleSubmit} autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      variant="standard"
                      label={t("contact.name")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={name}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => setName(e.target.value)}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKInput
                      type="email"
                      variant="standard"
                      label={t("contact.email")}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      value={email}
                      inputProps={{ maxLength: 100 }}
                      onChange={(e) => setEmail(e.target.value)}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      variant="standard"
                      label={t("contact.question")}
                      placeholder={t("contact.placeholder")}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                      value={message}
                      inputProps={{ maxLength: 250 }}
                      onChange={(e) => setMessage(e.target.value)}
                      error={Boolean(errors.message)}
                      helperText={errors.message}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info">
                  {t("contact.button")}
                  </MKButton>
                </Grid>
                {successMessage && (
                  <MKTypography variant="body2" color="success" align="center" mt={2}>
                    {successMessage}
                  </MKTypography>
                )}
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MainFooter/>
    </>
  );
}

export default ContactUs;
