/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React components
import MKBox from "components/MKBox";
import footerRoutes from "footer.routes";
import DefaultFooter from "../DefaultFooter";

function MainFooter() {
  return (
    <MKBox pt={6} px={1} mt={6}>
      <DefaultFooter footerRoutes={footerRoutes} />
    </MKBox>
  );
}


export default MainFooter;
