import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['fr'], // ['en', 'fr'],
    fallbackLng: 'fr',
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      queryStringParams: { v: Date.now() }, // Ajoute un cache-buster pour éviter la mise en cache
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    debug: true,
  });

export default i18n;
