/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/crypto-r.webp";
import bgBack from "assets/images/crypto-v.webp";

import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={t("info.card1_top.title")}
                description={t("info.card1_top.content")}
              />
              <RotatingCardBack
                image={bgBack}
                title={t("info.card1_bottom.title")}
                description={t("info.card1_bottom.content")}
                action={{
                  type: "internal", 
                  route: "/articles/pres-cryp",
                  label: t("info.click-here"),
                  color: "custom"
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="timeline"
                  title={t("info.card2.title")}
                  description={t("info.card2.content")}
                  />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="security"
                  title={t("info.card3.title")}
                  description={t("info.card3.content")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title={t("info.card4.title")}
                  description={t("info.card4.content")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title={t("info.card5.title")}
                  description={t("info.card5.content")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
